

import { Options } from "vue-class-component";

import PageBase from "@/shared/components/common/PageBase";
import HeaderMessage from "@/components/HeaderMessage.vue";
import { Global } from "@/support/GlobalData";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";
import { SignalR } from "@/support/SignalR";
import { CalibrationType } from "@/shared/enums/CalibrationType";

@Options({
    components: {
        HeaderMessage,
    }
})
export default class CalibrationComplete extends PageBase {

    get message(): string {
        if (Global.CalibrationSession?.CalibrationType === CalibrationType.Sequential)
            return "Targets have been successfully displayed.";
        else
            return "Target has been successfully displayed.";
    }

    acknowledgeClicked(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationEnded",
        } as DeviceStatusDto);

        Global.MainApp.clearSession();
        this.$router.push("/");
    }

    getCurrentStatusForListeners(): void {
        CalibrationEventSupport.sendEventStatus({
            Page: "CalibrationComplete",
        } as DeviceStatusDto);
    }

    mounted(): void {
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onAcknowledgeByCompanion = this.acknowledgeClicked;
        SignalR.onCalibrationSuccess = (): void => {
            // no action (we sent it in successClicked), avoid out of sync popup
        };
        this.getCurrentStatusForListeners();
        CalibrationEventSupport.sendBatteryInformation();// send final battery info
    }
    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onAcknowledgeByCompanion = null;
        SignalR.onCalibrationSuccess = null;
    }
}
