
import { Vue, Options } from "vue-class-component";
import "@/services/Auggie";
import { AUGGIEAVAILABLE } from "@/main";
import { Global } from "@/support/GlobalData";
import { SignalR, SignalRSupport } from "@/support/SignalR";
import { DataRequest } from "@/shared/support/Data";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";

@Options({
})
export default class Header extends Vue {

    pingOkBelow = Number(process.env.VUE_APP_PINGOKBELOW!);
    pingBadAbove = Number(process.env.VUE_APP_PINGBADABOVE!);

    isDebug(): boolean {
        return process.env.VUE_APP_DEBUG === "1";
    }
    get signalrEnabled(): boolean {
        return SignalRSupport.Enabled;
    }
    set signalrEnabled(value: boolean) {
        SignalRSupport.Enabled = value;
    }
    get apisEnabled(): boolean {
        return DataRequest.Enabled;
    }
    set apisEnabled(value: boolean) {
        DataRequest.Enabled = value;
    }
    debugActionClicked(): void {
        CalibrationEventSupport.sendEventDeviceFailure("Something failed.");
        CalibrationEventSupport.sendEvent(CalibrationEvent.AcknowledgeByDevice);
    }

    wifiActive(): boolean {
        if (Global.IsDemo()) return true;
        if (AUGGIEAVAILABLE && Global.IsVersionAtLeast("2023.133.0"))
            return Auggie.Network.CurrentNetworkType() === "wifi" || Auggie.Network.CurrentNetworkType() !== "ethernet";
        return true;
    }
    networkActive(): boolean {
        if (Global.IsDemo()) return false;
        if (AUGGIEAVAILABLE && Global.IsVersionAtLeast("2023.133.0"))
            return Auggie.Network.CurrentNetworkType() === "ethernet";
        return false;
    }

    networkClicked(): void {
        if (Global.MainApp.haveSession()) return;
        if (Global.IsDemo()) return;

        if (AUGGIEAVAILABLE)
            Auggie.System.WifiPage();
        else
            window.location.assign(process.env.VUE_APP_STATICWIFI!);
    }

    debugClicked(): void {
        if (Global.MainApp.haveSession()) return;

        if (AUGGIEAVAILABLE)
            Auggie.System.DebugPage();
        else
            window.location.assign(process.env.VUE_APP_STATICDEBUG!);
    }


    getNetworkCss(): string {
        if (Global.MainApp.haveSession()) return "defaultCursor";
        return "pointerCursor";
    }

    private networkStatusCss = "text-success me-1";

    updateNetworkStatus(): void {
        if (Global.IsDemo()) return;
        if (!SignalR.isConnected|| SignalR.isReconnecting) {
            this.setNetworkStatus("text-danger");
        } else {
            this.pingTest();
        }
    }

    setNetworkStatus(status: string): void {
        if (status === "")
            status = "text-success";
        this.networkStatusCss = `${status} me-1`;
    }

    pingTest(): number {
        const dr = new DataRequest();
        dr.autoToastOnFailure = false;

        const start = performance.now();

        dr.$getPure("/Ping")
            .then((data:string):void => {
                const end = performance.now();
                const ms = end - start;
                if (ms < this.pingOkBelow) {
                    this.setNetworkStatus("text-success");
                } else if (ms >= this.pingOkBelow && ms <= this.pingBadAbove) {
                    this.setNetworkStatus("text-warning");
                } else {
                    this.setNetworkStatus("text-danger");
                }
                if (Global.DetailLog) console.log(`Ping test responded within ${ms.toFixed(2)} ms`);
            })
            .catch((e:any): void => {
                this.setNetworkStatus("text-danger");
            });
        return 200;
    }

    hasBatteryInfo(): boolean {
        return !!Global.BatteryInfo;
    }
    batteryPercentage(): string {
        if (!Global.BatteryInfo) return "";
        return Global.BatteryInfo.PercentageFull.toFixed(0);
    }
    batteryLevelCss(): string {
        if (!Global.BatteryInfo) return "";
        if (Global.BatteryInfo.PercentageFull >= 51)
            return "text-success";
        if (Global.BatteryInfo.PercentageFull >= 21)
            return "text-warning";
        return "text-danger";
    }
    batteryLevelIcon(): string {
        if (!Global.BatteryInfo) return "";
        if (Global.BatteryInfo.ExternalPowerInd) {
            return  "bolt";
        } else {
            if (Global.BatteryInfo.PercentageFull >= 90)
                return  "battery-full";
            if (Global.BatteryInfo.PercentageFull >= 70)
                return  "battery-three-quarters";
            if (Global.BatteryInfo.PercentageFull >= 50)
                return  "battery-half";
            if (Global.BatteryInfo.PercentageFull >= 20)
                return  "battery-quarter";
            return  "battery-empty";
        }
    }

    handleDebugClicks(): void {
        this.$refs.logo.addEventListener("click", this.debugClickListener, false);
    }
    debugClickListener(e: Event): void {
        if (Global.MainApp.haveSession()) return;
        this.debugClicks++;
        if (!this.debugCounting) {
            this.debugCounting = true;
            this.debugTimeout = setTimeout((): void => {
                this.debugCounting = false;
                this.debugClicks = 0;
                this.debugTimeout = 0;
            }, 5000) as unknown as number;
        } else {
            if (this.debugClicks >= 5) {
                this.debugClicked();
                if (this.debugTimeout)
                    clearTimeout(this.debugTimeout);
                this.debugTimeout = 0;
                this.debugCounting = false;
                this.debugClicks = 0;
                this.debugTimeout = 0;
            }
        }
    }
    debugCounting = false;
    debugClicks = 0;
    debugTimeout = 0;

    public update(): void {
        this.$forceUpdate();
    }

    public created(): void {
        Global.MainHeader = this;
    }
    public static get MainHeader(): Header {
        return Global.MainHeader;
    }

    mounted(): void {
        this.handleDebugClicks();
        SignalR.onConnectionStartedHeader = ():void => { this.updateNetworkStatus(); };
        SignalR.onConnectionEndedHeader = ():void => { this.updateNetworkStatus(); };
        const pingInterval = Number(process.env.VUE_APP_PINGINTERVAL!);
        if (!Global.IsDemo() && pingInterval && pingInterval > 0) {
            setInterval((): void => {
                this.updateNetworkStatus();
            }, pingInterval * 1000);
        }
    }
    unmounted(): void {
        SignalR.onConnectionStartedHeader = null;
        SignalR.onConnectionEndedHeader = null;
    }

    declare $refs: {
        logo: HTMLDivElement;
    }
}

