import * as signalR from "@microsoft/signalr";
import Toast from "@/shared/support/Toast";
import Utility from "@/shared/support/Utility";
import { Global } from "./GlobalData";
import { CalibrationType } from "@/shared/enums/CalibrationType";
import { CalibrationEventSupport } from "./CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { AUGGIEAVAILABLE } from "@/main";
import { DataRequest } from "@/shared/support/Data";
import DeviceLogDto from "@/shared/models/DeviceLogDto";
import { RetryContext } from "@microsoft/signalr";

export enum DeviceStatus {
    Unknown = 0,
    Valid = 1,
    Invalid = 2,
}

export class SignalRSupport {

    // hub messages
    public static readonly DeviceAccepted = "DeviceAccepted";
    public static readonly DeviceRejected = "DeviceRejected";
    public static readonly TechnicianConnectedSession = "TechnicianConnectedSession";
    public static readonly TechnicianCameraRejected = "TechnicianCameraRejected";
    public static readonly CalibrationCanceledByTech = "CalibrationCanceledByTech";
    public static readonly CalibrationDisconnectedTech = "CalibrationDisconnectedTech";
    public static readonly CalibrationCanceledByCompanion = "CalibrationCanceledByCompanion";
    public static readonly CalibrationDisconnectedCompanion = "CalibrationDisconnectedCompanion";
    public static readonly CalibrationSuccess = "CalibrationSuccess";
    public static readonly CalibrationFailed = "CalibrationFailed";
    public static readonly VehicleTargetImageReady = "VehicleTargetImageReady";
    public static readonly VehicleSelectionByTechnician = "VehicleSelectionByTechnician";
    public static readonly GetCameraAlignmentImage = "GetCameraAlignmentImage";
    public static readonly CameraVerificationByTechnician = "CameraVerificationByTechnician";
    public static readonly CameraVerificationByCompanion = "CameraVerificationByCompanion";
    public static readonly RestartCalibration = "RestartCalibration";
    public static readonly RequestLightIntensity = "RequestLightIntensity";
    public static readonly SetLightIntensity = "SetLightIntensity";
    public static readonly GetCurrentStatusForListeners = "GetCurrentStatusForListeners";
    public static readonly AssistedCalibrationSelectedByCompanion = "AssistedCalibrationSelectedByCompanion";
    public static readonly AssistedTargetDisplaySelectedByCompanion = "AssistedTargetDisplaySelectedByCompanion";
    public static readonly SelfCalibrationSelectedByCompanion = "SelfCalibrationSelectedByCompanion";
    public static readonly SendTargetByCompanion = "SendTargetByCompanion";
    public static readonly SendPreviousTargetByCompanion = "SendPreviousTargetByCompanion";
    public static readonly SendNextTargetByCompanion = "SendNextTargetByCompanion";
    public static readonly AcknowledgeByCompanion = "AcknowledgeByCompanion";
    public static readonly VehicleSelectionByCompanion = "VehicleSelectionByCompanion";
    public static readonly VinSelectionByCompanion = "VinSelectionByCompanion";
    public static readonly StartTeamViewer = "StartTeamViewer";
    public static readonly WindshieldAngleSelectionByCompanion = "WindshieldAngleSelectionByCompanion";
    public static readonly ContactSupportWindshieldAngleAcknowledgeByCompanion = "ContactSupportWindshieldAngleAcknowledgeByCompanion";
    public static readonly MeasuredWindshieldAngleCorrectByCompanion = "MeasuredWindshieldAngleCorrectByCompanion";
    public static readonly RideHeightEntryByCompanion = "RideHeightEntryByCompanion";
    public static readonly UnverifiedVehicleContinueByCompanion = "UnverifiedVehicleContinueByCompanion";
    public static readonly UnverifiedVehicleBackByCompanion = "UnverifiedVehicleBackByCompanion";
    public static readonly BatteryLowAcknowledged20 = "BatteryLowAcknowledged20";
    public static readonly BatteryLowAcknowledged10 = "BatteryLowAcknowledged10";

    static Attempts = 10;// make 10 attempts to connect
    static AttemptInterval = 5*1000;
    public static Enabled = true; // debugging, turn off to disable signalr

    domain: string | undefined = undefined;

    public get connection(): signalR.HubConnection {
        if (!this.connectionCache) throw new Error("Attempted us of disconnected SignalR connection");
        return this.connectionCache;
    }
    private connectionCache: signalR.HubConnection|null = null;

    public get isConnected(): boolean {
        return this.isConnectedCache;
    }
    isConnectedCache = false;
    connecting = false;
    reconnecting = false;

    public get isReconnecting(): boolean {
        return this.reconnecting;
    }

    public getDeviceStatus(): DeviceStatus {
        return this.deviceStatus;
    }

    deviceStatus = DeviceStatus.Unknown;

    public onVehicleSelectionByTechnician: ((year: number, make: string, model: string, type: CalibrationType) => void) | null = null;
    public onTechnicianCameraRejected: (() => void) | null = null;
    public onCalibrationSuccess: (() => void) | null = null;
    public onCalibrationFailed: (() => void) | null = null;
    public onVehicleTargetImageReady: ((targetImageUploadId: number, targetNumber: number) => void) | null = null;
    public onTechnicianConnectedSession: ((techName: string) => void) | null = null;
    public onGetCameraAlignmentImage: (() => void) | null = null;
    public onCameraVerificationByTechnician: (() => void) | null = null;
    public onCameraVerificationByCompanion: (() => void) | null = null;
    public onRestartCalibration: (() => void) | null = null;
    public onRestartCalibrationFallback: (() => void) | null = null;
    public onSetLightIntensity: ((intensity: number) => void) | null = null;
    public onGetCurrentStatusForListeners: (() => void) | null = null;
    public onAssistedCalibrationSelectedByCompanion: (() => void) | null = null;
    public onAssistedTargetDisplaySelectedByCompanion: (() => void) | null = null;
    public onSelfCalibrationSelectedByCompanion: (() => void) | null = null;
    public onSendTargetByCompanion: (() => void) | null = null;
    public onSendPreviousTargetByCompanion: (() => void) | null = null;
    public onSendNextTargetByCompanion: (() => void) | null = null;
    public onAcknowledgeByCompanion: (() => void) | null = null;
    public onVehicleSelectionByCompanion: ((year: number, make: string, model: string, type: CalibrationType) => void) | null = null;
    public onVinSelectionByCompanion: ((vin: string) => void) | null = null;
    public onWindshieldAngleSelectionByCompanion: ((angle: number) => void) | null = null;
    public onContactSupportWindshieldAngleAcknowledgeByCompanion: (() => void) | null = null;
    public onMeasuredWindshieldAngleCorrectByCompanion: ((correct: boolean) => void) | null = null;
    public onRideHeightEntryByCompanion: (() => void) | null = null;
    public onUnverifiedVehicleContinueByCompanion: (() => void) | null = null;
    public onUnverifiedVehicleBackByCompanion: (() => void) | null = null;
    public onBatteryLowAcknowledged20: (() => void) | null = null;
    public onBatteryLowAcknowledged10: (() => void) | null = null;

    // reserved for Header component
    public onConnectionStartedHeader: (() => void) | null = null;
    public onConnectionEndedHeader: (() => void) | null = null;

    public start(domain?: string): void {

        // Note that the domain has to be the URL of the FUNCTION, not Azure SignalR
        // The initial Negotiate call (to the Function) establishes the actual SignalR URL
        // see https://docs.microsoft.com/en-us/azure/azure-signalr/signalr-concept-serverless-development-config#sending-messages-from-a-client-to-the-service

        if (this.isConnected || this.connecting) return;
        if (domain)
            this.domain = domain;

        if (Global.IsDemo()) {
            Global.DeviceGuid = "000000000-0000-0000-0000-000000000000";
            Global.DeviceName = "Demo Device";
            Global.AllowAssistedCalibration = true;
            Global.AllowAssistedTargetDisplay = true;
            Global.AllowSelfCalibration = true;
            Global.VerifiedVehiclesOnlyInd = false;
            Global.DetailLog = false;
            this.deviceStatus = DeviceStatus.Valid;
            this.connecting = false;
            this.isConnectedCache = true;
            if (this.onConnectionStartedHeader)
                this.onConnectionStartedHeader();
            if (AUGGIEAVAILABLE) Auggie.System.DeviceWebSuccessful();
            return;
        }

        Global.DeviceGuid = "";
        this.deviceStatus = DeviceStatus.Unknown;
        this.connecting = true;
        this.connectionCache = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Error)
            .withAutomaticReconnect(
                {
                    nextRetryDelayInMilliseconds: (retryContext: RetryContext): number | null => { return this.nextRetryDelayInMilliseconds(retryContext); },
                })
            .withUrl(Utility.formatUrl("", { }, this.domain))
            .build();
        this.bindConnection();

        if (Global.IsVersionAtLeast("0.9.11")) {
            if (AUGGIEAVAILABLE) Auggie.System.DeviceWebRestart();
        }

        console.log("SignalR starting...");
        this.connectionCache.start()
            .then((): void => {
                // if (process.env.VUE_APP_DEBUG === "1") { // DEBUG CODE, pretend connection fails until last attempt
                //     if (SignalRSupport.Attempts !== 1)
                //         throw new Error("pretend Signal Connection failed");
                // }

                console.log("SignalR connection started");
                this.connecting = false;
                this.isConnectedCache = true;
                if (this.onConnectionStartedHeader)
                    this.onConnectionStartedHeader();

                this.setupClientPing();

                if (Global.IsVersionAtLeast("0.9.11")) {
                    // The device web has been started and is usable
                    // (other things may still fail, but the device web can display these errors)
                    if (AUGGIEAVAILABLE) Auggie.System.DeviceWebSuccessful();
                }
            })
            .catch((error): void => {
                this.connecting = false;
                console.error(`SignalR connection failed: ${error?.message}`);
                if (this.onConnectionEndedHeader)
                    this.onConnectionEndedHeader();

                // Retry connection up to maximum times
                if (SignalRSupport.Attempts > 0) {

                    setTimeout((): void => {
                        console.error(`Attempting SignalR connection start - remaining attempts: ${SignalRSupport.Attempts}`);
                        SignalRSupport.Attempts--;
                        this.start(this.domain);
                    }, SignalRSupport.AttemptInterval);
                } else {
                    // We failed, show Wifi settings / Try Again / Restart Auggie Page
                    if (Global.IsVersionAtLeast("0.9.11")) {
                        // The device web can't be started
                        if (AUGGIEAVAILABLE) Auggie.System.DeviceWebFailed(error?.message || "SignalR connection failed");
                    }
                }
            });
    }
    nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
        console.error(`SignalR retry attemt: ${retryContext.retryReason}`);
        if (retryContext.previousRetryCount <= 0) return 0;// 1st time immediately
        else if (retryContext.previousRetryCount === 1) return 1*1000;// 1 second
        else if (retryContext.previousRetryCount === 2) return 2*1000;// 2 seconds
        else return 10*1000;// after 3+ retry attempts we'll keep retrying every 10 seconds forever
    }

    private clientPingInterval = 0;

    setupClientPing(): void {
        if (this.clientPingInterval) return;
        const pingInterval = Number(process.env.VUE_APP_CLIENTPINGFREQUENCY) * 1000;
        if (isNaN(pingInterval)) throw new Error("SignalR: VUE_APP_CLIENTPINGFREQUENCY not defined in .env");
        console.debug(`PING ==> ${pingInterval}`);
        this.clientPingInterval = setInterval((): void => {
            if (!this.isConnected) return;
            console.debug(`SignalR: Client Ping ${window.location.pathname}`);
            this.connection.send("PageNavigation", window.location.pathname);
        }, pingInterval) as unknown as number;
    }

    bindConnection(): void {

        this.connection.onclose((error: Error | undefined): void => {
            if (error && error.message)
                console.error(error.message);
            this.isConnectedCache = false;
            this.deviceStatus = DeviceStatus.Unknown;
            Toast.error("The server connection has been lost (SignalR)");
            if (this.onConnectionEndedHeader)
                this.onConnectionEndedHeader();

        });
        this.connection.onreconnecting((error: Error | undefined): void => {
            console.log(`SignalR reconnecting... ${error?.message}`);
            this.reconnecting = true;
        });
        this.connection.onreconnected((connectionId?: string):void => {
            console.log("SignalR reconnected");
            this.reconnecting = false;
        });

        this.connection.on(SignalRSupport.DeviceRejected, (message: string): void => {
            if (!SignalRSupport.Enabled) return;
            console.error(`${SignalRSupport.DeviceRejected} ${message}`);
            this.deviceStatus = DeviceStatus.Invalid;
            Global.DeviceGuid = "";
            Global.MainHome.updateDeviceInfo();
            Toast.error(message);
        });
        this.connection.on(SignalRSupport.DeviceAccepted, (deviceGuid: string, deviceName: string,
            allowAssistedCalibration: boolean, allowSelfCalibration: boolean, detailLog: boolean, allowAssistedTargetDisplay: boolean,
            lightIntensity: number, verifiedVehiclesOnlyInd: boolean): void => {
            if (!SignalRSupport.Enabled) return;
            this.deviceStatus = DeviceStatus.Valid;
            Global.DeviceGuid = deviceGuid;
            Global.DeviceName = deviceName;
            Global.AllowAssistedCalibration = allowAssistedCalibration;
            Global.AllowAssistedTargetDisplay = allowAssistedTargetDisplay;
            Global.AllowSelfCalibration = allowSelfCalibration;
            Global.VerifiedVehiclesOnlyInd = verifiedVehiclesOnlyInd;
            Global.LightIntensity = lightIntensity;
            Global.DetailLog = detailLog;
            Global.MainHome.updateDeviceInfo();

            // Phone Home
            if (AUGGIEAVAILABLE) {
                const dr = new DataRequest();
                dr.autoToastOnFailure = false;
                const logEntry = { DeviceGuid: deviceGuid, Version: Auggie.System.Version };
                dr.$post<DeviceLogDto>("/Service/DeviceLog", null, logEntry); // fire and forget
            }
        });

        this.connection.on(SignalRSupport.CalibrationCanceledByTech, (reason: string|null): void => {
            if (!SignalRSupport.Enabled) return;
            if (Global.MainApp.haveSession())
                Toast.warning(`The technician has canceled this calibration session${reason ? ` - ${reason}` : ""}`);
            Global.MainApp.clearSession();
            window.location.assign("/");
        });
        this.connection.on(SignalRSupport.CalibrationDisconnectedTech, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (Global.MainApp.haveSession())
                Toast.warning("The technician has disconnected and the calibration is canceled.");
            Global.MainApp.clearSession();
            window.location.assign("/");
        });

        this.connection.on(SignalRSupport.CalibrationCanceledByCompanion, (reason: string|null): void => {
            if (!SignalRSupport.Enabled) return;
            if (Global.MainApp.haveSession())
                Toast.warning(`This calibration session has been canceled${reason ? ` - ${reason}` : ""}`);
            Global.MainApp.clearSession();
            window.location.assign("/");
        });
        this.connection.on(SignalRSupport.CalibrationDisconnectedCompanion, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (Global.MainApp.haveSession())
                Toast.warning("This Companion App has disconnected and the calibration session is canceled");
            Global.MainApp.clearSession();
            window.location.assign("/");
        });


        this.connection.on(SignalRSupport.VehicleSelectionByTechnician, (year: number, make: string, model: string, type: CalibrationType): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onVehicleSelectionByTechnician)
                this.onVehicleSelectionByTechnician(year, make, model, type);
            else {
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.VehicleSelectionByTechnician);
            }
        });
        this.connection.on(SignalRSupport.VehicleSelectionByCompanion, (year: number, make: string, model: string, type: CalibrationType): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onVehicleSelectionByCompanion)
                this.onVehicleSelectionByCompanion(year, make, model, type);
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.VehicleSelectionByCompanion);
        });
        this.connection.on(SignalRSupport.VinSelectionByCompanion, (vin: string): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onVinSelectionByCompanion)
                this.onVinSelectionByCompanion(vin);
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.VinSelectionByCompanion);
        });

        this.connection.on(SignalRSupport.TechnicianCameraRejected, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onTechnicianCameraRejected)
                this.onTechnicianCameraRejected();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.TechnicianCameraRejected);
        });
        this.connection.on(SignalRSupport.CalibrationSuccess, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onCalibrationSuccess)
                this.onCalibrationSuccess();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.CalibrationSuccess);
        });
        this.connection.on(SignalRSupport.CalibrationFailed, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onCalibrationFailed)
                this.onCalibrationFailed();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.CalibrationFailed);
        });
        this.connection.on(SignalRSupport.VehicleTargetImageReady, (targetImageUploadId: number, targetNumber: number): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onVehicleTargetImageReady)
                this.onVehicleTargetImageReady(targetImageUploadId, targetNumber);
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.VehicleTargetImageReady);
        });
        this.connection.on(SignalRSupport.TechnicianConnectedSession, (techName: string): void => {
            if (!SignalRSupport.Enabled) return;
            Toast.info("A technician has accepted the calibration session");
            Global.TechnicianName = techName;
            if (this.onTechnicianConnectedSession)
                this.onTechnicianConnectedSession(techName);
        });
        this.connection.on(SignalRSupport.GetCameraAlignmentImage, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onGetCameraAlignmentImage)
                this.onGetCameraAlignmentImage();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.GetCameraAlignmentImage);
        });
        this.connection.on(SignalRSupport.CameraVerificationByTechnician, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onCameraVerificationByTechnician)
                this.onCameraVerificationByTechnician();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.CameraVerificationByTechnician);
        });
        this.connection.on(SignalRSupport.CameraVerificationByCompanion, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onCameraVerificationByCompanion)
                this.onCameraVerificationByCompanion();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.CameraVerificationByCompanion);
        });
        this.connection.on(SignalRSupport.RestartCalibration, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onRestartCalibration)
                this.onRestartCalibration();
            else if (this.onRestartCalibrationFallback)
                this.onRestartCalibrationFallback();
        });
        this.connection.on(SignalRSupport.RequestLightIntensity, (): void => {
            if (!SignalRSupport.Enabled) return;
            CalibrationEventSupport.sendEventCurrentLightIntensity(CalibrationEvent.CurrentLightIntensity, Global.LightIntensity);
        });
        this.connection.on(SignalRSupport.SetLightIntensity, (intensity: number): void => {
            if (!SignalRSupport.Enabled) return;
            Global.LightIntensity = intensity;
            CalibrationEventSupport.sendEventCurrentLightIntensity(CalibrationEvent.CurrentLightIntensity, Global.LightIntensity);
            if (this.onSetLightIntensity)
                this.onSetLightIntensity(intensity);
        });
        this.connection.on(SignalRSupport.StartTeamViewer, (techGuid: string): void => {
            if (!SignalRSupport.Enabled) return;
            if (AUGGIEAVAILABLE) {
                if (Auggie.TeamViewer.On()) {
                    Toast.success("Remote Assistance has been enabled.");
                    CalibrationEventSupport.sendEventTvStarted(CalibrationEvent.TeamViewerStarted, Global.DeviceName, techGuid);
                }
            }
        });


        // Companion Support

        this.connection.on(SignalRSupport.GetCurrentStatusForListeners, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onGetCurrentStatusForListeners)
                this.onGetCurrentStatusForListeners();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.GetCurrentStatusForListeners);
        });
        this.connection.on(SignalRSupport.AssistedCalibrationSelectedByCompanion, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onAssistedCalibrationSelectedByCompanion)
                this.onAssistedCalibrationSelectedByCompanion();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.AssistedCalibrationSelectedByCompanion);
        });
        this.connection.on(SignalRSupport.AssistedTargetDisplaySelectedByCompanion, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onAssistedTargetDisplaySelectedByCompanion)
                this.onAssistedTargetDisplaySelectedByCompanion();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.AssistedTargetDisplaySelectedByCompanion);
        });
        this.connection.on(SignalRSupport.SelfCalibrationSelectedByCompanion, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onSelfCalibrationSelectedByCompanion)
                this.onSelfCalibrationSelectedByCompanion();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.SelfCalibrationSelectedByCompanion);
        });
        this.connection.on(SignalRSupport.SendTargetByCompanion, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onSendTargetByCompanion)
                this.onSendTargetByCompanion();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.SendTargetByCompanion);
        });
        this.connection.on(SignalRSupport.SendPreviousTargetByCompanion, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onSendPreviousTargetByCompanion)
                this.onSendPreviousTargetByCompanion();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.SendPreviousTargetByCompanion);
        });
        this.connection.on(SignalRSupport.SendNextTargetByCompanion, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onSendNextTargetByCompanion)
                this.onSendNextTargetByCompanion();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.SendNextTargetByCompanion);
        });
        this.connection.on(SignalRSupport.AcknowledgeByCompanion, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onAcknowledgeByCompanion)
                this.onAcknowledgeByCompanion();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.AcknowledgeByCompanion);
        });
        this.connection.on(SignalRSupport.MeasuredWindshieldAngleCorrectByCompanion, (correct: boolean): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onMeasuredWindshieldAngleCorrectByCompanion)
                this.onMeasuredWindshieldAngleCorrectByCompanion(correct);
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.MeasuredWindshieldAngleCorrectByCompanion);
        });
        this.connection.on(SignalRSupport.WindshieldAngleSelectionByCompanion, (angle: number): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onWindshieldAngleSelectionByCompanion)
                this.onWindshieldAngleSelectionByCompanion(angle);
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.WindshieldAngleSelectionByCompanion);
        });
        this.connection.on(SignalRSupport.ContactSupportWindshieldAngleAcknowledgeByCompanion, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onContactSupportWindshieldAngleAcknowledgeByCompanion)
                this.onContactSupportWindshieldAngleAcknowledgeByCompanion();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.ContactSupportWindshieldAngleAcknowledgeByCompanion);
        });

        this.connection.on(SignalRSupport.RideHeightEntryByCompanion, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onRideHeightEntryByCompanion)
                this.onRideHeightEntryByCompanion();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.RideHeightEntryByCompanion);
        });
        this.connection.on(SignalRSupport.UnverifiedVehicleContinueByCompanion, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onUnverifiedVehicleContinueByCompanion)
                this.onUnverifiedVehicleContinueByCompanion();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.UnverifiedVehicleContinueByCompanion);
        });
        this.connection.on(SignalRSupport.UnverifiedVehicleBackByCompanion, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onUnverifiedVehicleBackByCompanion)
                this.onUnverifiedVehicleBackByCompanion();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.UnverifiedVehicleBackByCompanion);
        });
        this.connection.on(SignalRSupport.BatteryLowAcknowledged20, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onBatteryLowAcknowledged20)
                this.onBatteryLowAcknowledged20();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.BatteryLowAcknowledged20);
        });
        this.connection.on(SignalRSupport.BatteryLowAcknowledged10, (): void => {
            if (!SignalRSupport.Enabled) return;
            if (this.onBatteryLowAcknowledged10)
                this.onBatteryLowAcknowledged10();
            else
                CalibrationEventSupport.sendEventOutOfSync(SignalRSupport.BatteryLowAcknowledged10);
        });
    }
}

export const SignalR = new SignalRSupport();
