
import { ButtonClicked } from "@/shared/enums/ButtonClicked";
import { confirmOk, confirmYesNo } from "@/shared/components/common/AlertDialog.vue";
import PageBase from "@/shared/components/common/PageBase";
import { CalibrationType } from "@/shared/enums/CalibrationType";
import { AUGGIEAVAILABLE } from "@/main";
import { CalibrationSessionDto } from "@/shared/models/CalibrationSessionDto";
import { PackageDto } from "@/shared/models/PackageDto";
import { DataRequest } from "@/shared/support/Data";
import { Global } from "@/support/GlobalData";
import { DeviceStatus, SignalR } from "@/support/SignalR";
import { Options } from "vue-class-component";
import HeaderMessage from "@/components/HeaderMessage.vue";
import PowerOff from "@/components/PowerOff.vue";
import Utility from "@/shared/support/Utility";
import Toast from "@/shared/support/Toast";
import Qrcode from "@/components/Qrcode.vue";
import { CalibrationEventSupport } from "@/support/CalibrationEvent";
import { CalibrationEvent } from "@/shared/enums/CalibrationEvent";
import { SessionType } from "@/shared/enums/SessionType";
import { DeviceStatusDto } from "@/shared/models/DeviceStatusDto";

@Options({
    components: {
        HeaderMessage,
        PowerOff,
        Qrcode,
    }
})
export default class Home extends PageBase {

    getDeviceStatus(): DeviceStatus {
        return SignalR.getDeviceStatus();
    }
    isValidDevice(): boolean {
        return this.getDeviceStatus() === DeviceStatus.Valid && !this.isPackageTooNew();
    }
    isInvalidDevice(): boolean {
        return this.getDeviceStatus() === DeviceStatus.Invalid && !this.isPackageTooNew();
    }
    isUnknownDevice(): boolean {
        return this.getDeviceStatus() === DeviceStatus.Unknown && !this.isPackageTooNew();
    }
    isDemo(): boolean {
        return Global.IsDemo();
    }
    get allowAssistedCalibration(): boolean {
        return Global.AllowAssistedCalibration;
    }
    get allowAssistedTargetDisplay(): boolean {
        return Global.AllowAssistedTargetDisplay;
    }
    get allowSelfCalibration(): boolean {
        return Global.AllowSelfCalibration;
    }
    get buttonGap(): string {
        let count = 0;
        if (this.allowAssistedCalibration) ++count;
        if (this.allowAssistedTargetDisplay) ++count;
        if (this.allowSelfCalibration) ++count;

        switch (count) {
            case 3: return "mt-3";
            case 2: return "mt-5";
            default: return "";
        }
    }
    isPackageTooNew(): boolean {
        return Global.IsVersionBeyondLastSupported();
    }
    packageTooNewText(): string {
        // The device version is too high and not yet supported by the device web
        if (!AUGGIEAVAILABLE) return "";
        return `Device client version ${Auggie.System.Version} is not yet supported by this environment (${Auggie.System.GetEnvironment()}).`;
    }
    packageLatestText(): string {
        return `Latest supported device client version is ${process.env.VUE_APP_LASTSUPPORTEDDEVICEVERSION}`;
    }

    getVersion(): string {
        let version = "n/a";
        if (AUGGIEAVAILABLE)
            version = Auggie.System.Version;
        return `Auggie ${process.env.VUE_APP_VERSION} (${version})`;
    }

    getEnvironment(): string {
        if (Global.IsDemo()) return " - DEMO";
        if (Global.Environment === "PRD") return "";
        return ` - ${Global.Environment}`;
    }

    get url(): string { return this.urlCache; }
    set url(url: string) { this.urlCache = url; }
    urlCache = "";

    get haveUrl(): boolean {
        return this.url.length > 0;
    }
    get isDebug(): boolean {
        return process.env.VUE_APP_DEBUG === "1";
    }

    onClickedAssist(): void {
        this.loading = true;
        Global.SessionType = SessionType.AssistedCalibration;
        CalibrationEventSupport.sendEvent(CalibrationEvent.AssistedCalibrationSelected);
        setTimeout((): void => {// allow page update in case it takes longer
            this.$router.push("/Calibration");
        }, 100);
    }
    onClickedAssistTargetDisplay(): void {
        this.loading = true;
        Global.SessionType = SessionType.AssistedTargetDisplay;
        CalibrationEventSupport.sendEvent(CalibrationEvent.AssistedTargetDisplaySelected);
        setTimeout((): void => {// allow page update in case it takes longer
            this.$router.push("/Calibration");
        }, 100);
    }
    onClickedSelf(): void {
        this.loading = true;
        Global.SessionType = SessionType.SelfCalibration;
        CalibrationEventSupport.sendEvent(CalibrationEvent.SelfCalibrationSelected);
        setTimeout((): void => {// allow page update in case it takes longer
            this.$router.push("/Calibration");
        }, 100);
    }

    onOffClicked(): void {
        confirmYesNo("Are you sure you want to shut down this device?")
            .then((clicked: ButtonClicked): void => {
                if (clicked === ButtonClicked.Ok) {
                    confirmOk("Please make sure to power off the device once it has shut down.")
                        .then((clicked: ButtonClicked): void => {
                            Toast.success("Shutting down ....");
                            if (AUGGIEAVAILABLE) {
                                setTimeout((): void => {
                                    Auggie.System.ShutDown();
                                }, 2000);// slight delay so the toast is shown
                            }
                        });
                }
            });
    }

    setVehicleSelectionByTech(year: number, make: string, model: string, type: CalibrationType): void {
        Global.IsTechControlled = true;
        if (Global.SessionType === SessionType.Unknown)
            Global.SessionType = SessionType.AssistedCalibration;
        Global.Make = make;
        Global.Model = model;
        Global.Year = year;
        Global.Calibration = type;
        setTimeout((): void => {// allow page update in case it takes longer
            this.$router.push("/Calibration");
        }, 100);
    }

    MaxStartSessionAttemps = 10;
    StartSessionInterval = 5*1000;

    startConnection(): void {
        if (Global.DetailLog) console.log(`Entry: startConnection - device status ${this.getDeviceStatus()}`);
        this.loading = false;
        if (this.isInvalidDevice() || this.isPackageTooNew()) return;// this device can't do calibrations
        this.loading = true;
        if (this.isUnknownDevice()) {
            if (Global.DetailLog) console.log("Unknown device in startConnection");
            // we don't have a device yet (device session not yet started through SignalR)
            if (this.MaxStartSessionAttemps > 0) {
                // start a device session
                if (Global.DetailLog) console.log("sending deviceStartSession");
                if (SignalR.isConnected) {
                    // start a device session, count as an attempt
                    if (Global.DetailLog) console.log("startConnection, sending deviceStartSession");
                    SignalR.connection.send("deviceStartSession", Global.DeviceSerialNumber);
                    this.MaxStartSessionAttemps--;
                } else {
                    // we're still? waiting for SignalR, so this doesn't count as a failed attempt
                    if (Global.DetailLog) console.log("startConnection, waiting for SignalR");
                }
                setTimeout((): void => {
                    this.startConnection(); // try to start the session again
                }, this.StartSessionInterval);
            } else {
                this.loading = false;
                if (Global.DetailLog) console.log("startConnection, unable to get response to deviceStartSession");
                confirmOk("Unable to start a session for this device. Possible server connectivity problems or weak WiFi signal.")
                    .then((clicked: ButtonClicked): void => {
                        window.location.href = "/";// start from scratch by reloading the page
                    });
            }
            return;
        }
        this.loading = false;
        if (Global.DetailLog) console.log("startConnection OK");

        if (!AUGGIEAVAILABLE) {
            this.startSession();
            return;
        }

        Global.MainApp.clearSession();

        if (!Global.IsPrototype() && !Global.IsDemo()) {

            this.loading = true;

            let deviceGuid: string|null = null;
            let osDescription: string|null = null;
            let osArchitecture: string|null = null;
            if (Global.IsVersionAtLeast("2024.101.1")) {
                deviceGuid = Global.DeviceGuid;
                osDescription = Auggie.System.OsDescription;
                osArchitecture = Auggie.System.OsArchitecture;
            }
            const dr = new DataRequest();
            dr.autoToastOnFailure = false;
            dr.$get<PackageDto>("/Service/Packages/PackageInformation", {
                Version: Auggie.System.Version,
                DeviceGuid: deviceGuid,
                OsDescription: osDescription,
                osArchitecture: osArchitecture
            }).then((auggiePackage: PackageDto): void => {
                this.loading = false;
                if (auggiePackage.PackageId) {
                    // There is a new package
                    if (Global.DetailLog) console.log(`Package ${auggiePackage.PackageName} available - ${JSON.stringify(auggiePackage)}`);
                    Global.PackageUpgrade = auggiePackage;
                    const url = Utility.formatUrl(`/Service/Packages/GetPackage/${Global.PackageUpgrade!.PackageName}`, {}, Global.GetWebAPIUrl());
                    const err = Auggie.PackageDownload.Start(url,
                        Global.PackageUpgrade!.PackageName,
                        Global.PackageUpgrade!.Size, Global.PackageUpgrade!.Hash,
                        `${process.env.VUE_APP_DEVICE_SERIAL_NUMBER_HEADER!}: ${Global.DeviceSerialNumber}`,
                        `Authorization: Bearer ${Auggie.System.GetToken()}` );
                    if (auggiePackage.RequiredInstall || (auggiePackage.InstallBy && new Date(auggiePackage.InstallBy) < new Date())) {
                        // required install
                        if (!err)
                            this.$router.push("/Upgrade/Required");
                        else
                            this.$router.push("/Upgrade/Failed");
                    } else {
                        // background download was started/resumed
                        if (Auggie.PackageDownload.IsDone()) {
                            confirmYesNo("An upgrade is available for this device. Do you want to install it now?")
                                .then((clicked: ButtonClicked): void => {
                                    if (clicked === ButtonClicked.Ok) {
                                        this.$router.push("/Upgrade/Installing");
                                        return;
                                    }
                                    this.startSession();
                                });
                        } else {
                            this.startSession();
                        }
                    }
                } else {
                    this.startSession();
                }
            }).catch((reason: any): void => {
                this.loading = false;
                this.startSession();
            });
        } else {
            this.startSession();
        }
    }

    startSession(): void {

        if (Global.IsDemo()) {
            Global.MainApp.setDemoSession();
            this.url = Utility.formatUrl("/not a real url", { });
            return;
        }

        this.loading = true;

        Global.MainApp.clearSession();

        let packageVersion = "";
        if (AUGGIEAVAILABLE)
            packageVersion = Auggie.System.Version;

        const dr = new DataRequest();
        dr.autoToastOnFailure = false;
        const calibrationSession = {
            DeviceGuid: Global.DeviceGuid,
            PackageVersion: packageVersion,
            SiteVersion: process.env.VUE_APP_VERSION || "",
        } as CalibrationSessionDto;
        dr.$post<CalibrationSessionDto, CalibrationSessionDto>("/Service/CalibrationSession", null, calibrationSession)
            .then((session: CalibrationSessionDto): void => {
                this.loading = false;
                if (session.UpdateResult.Success) {
                    Global.MainApp.setSession(session);
                    this.url = Utility.formatUrl("/", { c: Global.CalibrationSession!.CompanionGuid, s: Global.CalibrationSession!.CalibrationSessionGuid }, Global.GetCompanionUrl());
                    CalibrationEventSupport.sendBatteryInformation();// send initial battery info
                    if (AUGGIEAVAILABLE) {
                        if (Global.IsVersionAtLeast("2024.101.1")) {
                            const urlChunked = Utility.formatUrl("/Service/ChunkedUpload", {}, Global.GetWebAPIUrl());// chunked uploads in background (WINDOWS)
                            const url = Utility.formatUrl("/Service/Uploads/UploadLog", {}, Global.GetWebAPIUrl());// uploads in background (LINUX)
                            Auggie.System.UploadLogsChunked(urlChunked, url, Global.DeviceName, Global.CalibrationSession!.CalibrationSessionGuid,
                                `${process.env.VUE_APP_DEVICE_SERIAL_NUMBER_HEADER!}: ${Global.      DeviceSerialNumber}`, `Authorization: Bearer ${Auggie.System.GetToken()}`);
                        } else if (Global.IsVersionAtLeast("2023.019.12")) {
                            const url = Utility.formatUrl("/Service/Uploads/UploadLog", {}, Global.GetWebAPIUrl());
                            Auggie.System.UploadLogs(url, Global.DeviceName, `${process.env.VUE_APP_DEVICE_SERIAL_NUMBER_HEADER!}: ${Global.DeviceSerialNumber}`, `Authorization: Bearer ${Auggie.System.GetToken()}`);
                        }
                    }
                } else {
                    confirmOk(session.UpdateResult.Message || "The server was unable to establish a session")
                        .then((clicked: ButtonClicked): void => {
                            this.startConnection();// try again
                        });
                }
            })
            .catch((reason: any): void => {
                this.loading = false;
                confirmOk("Unable to establish a session for this device. Possible server connectivity problems or weak WiFi signal.")
                    .then((clicked: ButtonClicked): void => {
                        this.startConnection();// try again
                    });
            });
    }

    killTeamViewer(): void {
        // Shut down TeamViewer
        if (AUGGIEAVAILABLE && Global.IsVersionAtLeast("2022.286.2")) {
            Auggie.TeamViewer.Off();
        }
    }

    public static get MainHome(): Home {
        return Global.MainHome;
    }

    public updateDeviceInfo(): void {
        this.$forceUpdate();
    }

    public created(): void {
        Global.MainHome = this;
    }

    getCurrentStatusForListeners(): void {
        CalibrationEventSupport.sendEventStatus({ Page: "Home" } as DeviceStatusDto);
    }

    mounted(): void {
        this.killTeamViewer();
        this.startConnection();
        SignalR.onGetCurrentStatusForListeners = this.getCurrentStatusForListeners;
        SignalR.onVehicleSelectionByTechnician = this.setVehicleSelectionByTech;
        SignalR.onAssistedCalibrationSelectedByCompanion = this.onClickedAssist;
        SignalR.onAssistedTargetDisplaySelectedByCompanion = this.onClickedAssistTargetDisplay;
        SignalR.onSelfCalibrationSelectedByCompanion = this.onClickedSelf;
        document.addEventListener("contextmenu", (ev: MouseEvent): void => {
            ev.preventDefault();
        });
    }
    unmounted(): void {
        SignalR.onGetCurrentStatusForListeners = null;
        SignalR.onVehicleSelectionByTechnician = null;
        SignalR.onAssistedCalibrationSelectedByCompanion = null;
        SignalR.onAssistedTargetDisplaySelectedByCompanion = null;
        SignalR.onSelfCalibrationSelectedByCompanion = null;
    }
}
